.card {
  height: 100%;

  .card-body {
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-subtitle {
      max-width: 100%;
    }

    img {
      max-width: 70px;
      margin-bottom: 35px;
    }

    h5 {
      font-weight: 300;
      color: $grey-light;
    }
  }
}

.support-package-cards {
  padding-bottom: 0;
  @include breakpoint(medium) {
    padding-bottom: 50px;
  }

  .col-lg-4 {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;

    .card {
      width: 100%;
      @include breakpoint(large) {
        width: 320px;
      }

      a {
        display: none; // Temp hide Learn more button
        margin-top: auto;
        font-size: 20px;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }

    .details,
    .spinner-border {
      display: none;
    }

    .details {
      font-size: 20px;
    }
  }
}
