footer {
  padding: 86px 0;

  .logo-footer {
    max-width: 260px;
    margin-bottom: 1rem;
    margin-top: -0.9rem;
    margin-left: -0.9rem;
  }
  h4 {
    color: $grey-dark;
    margin-bottom: 1rem;
  }
  p {
    color: $grey-footer;
  }
  a {
    color: $blue;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .col-lg-4 {
    text-align: center;
    margin-bottom: 50px;

    @include breakpoint(large) {
      text-align: left;
      margin-bottom: 0;
    }
  }
  .col-lg-4:first-child {
    p {
      max-width: 270px;
      margin: auto;

      @include breakpoint(large) {
        margin: 0;
      }
    }
  }
}
