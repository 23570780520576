/* global base styles */
html,
body {
  font-family: "Avenir", sans-serif;
  font-weight: normal;
  font-size: 16px;
  background-color: $grey-body;
}

h1 {
  font-size: 50px;
  line-height: 1.4;
  letter-spacing: 1.25px;
  font-weight: 500;
}

h2 {
  font-weight: 500;
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: 1.25px;
  font-weight: normal;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: 0.6px;
  font-weight: normal;
}

h5 {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

h6 {
  font-size: 18px;
}

section {
  padding: 50px 0;
}

p {
  font-weight: 300;
  line-height: 1.63;
  letter-spacing: 0.4px;
}

.section-title {
  text-align: center;
  margin-bottom: 90px;

  &:after {
    content: "";
    width: 120px;
    height: 5px;
    background-color: $blue;
    margin: auto;
    margin-top: 24px;
    display: block;
  }
}

.round-conor {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 15px;
  border-radius: 40px;
  transition: all 0.3s ease 0s;
  height: 60px;
  padding: 20px 40px;
  color: $white;
  border: 2px solid transparent;
  background: $blue-dark;
  min-width: 300px;

  &:hover,
  &:focus {
    border: 2px solid $white;
    background: $blue;
  }

  &.btn-light {
    border: 2px solid $white;
    background: transparent;

    &:hover,
    &:focus {
      color: $blue;
      background: white;
      border: 2px solid $blue;
    }
  }
}
