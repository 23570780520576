.site-header {
  background: #2233b4;

  .navbar-brand {
    max-width: 70%;
    img {
      width: 300px;
    }
  }
  nav.main-nav {
    padding: 48px 15px;

    @include breakpoint(large) {
      padding: 48px 0;
    }

    > .container {
      @include breakpoint(xxlarge) {
        max-width: 1440px;
      }
    }
  }
  .navbar-toggler {
    border: 0;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-nav .nav-link {
    line-height: 2.25;
    letter-spacing: 0.4px;
    color: $white;
  }

  .nav-item:first-child {
    font-weight: 500;
    position: relative;
    padding-right: 16px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      right: 6px;
      top: 10px;
      height: 33px;
      width: 2px;
      background: $white;

      @include breakpoint(large) {
        display: block;
      }
    }
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
