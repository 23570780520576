.drupal-support-benefits {
  color: $white;
  position: relative;
  padding: 50px 0;

  @include breakpoint(large) {
    padding: 90px 0 50px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 960px;
    background-image: url("../img/bg-wave-blue.svg");
    background-repeat: no-repeat;
    background-size: 1000px;
    background-position: top center;

    @include breakpoint(large) {
      background-size: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: calc(100% - 380px);
    background: $blue-dark;
    @include breakpoint(medium) {
      height: 760px;
    }
  }

  .section-content {
    margin-bottom: 50px;
    padding: 50px 0;
    flex-direction: column;

    @include breakpoint(medium) {
      padding: 0;
    }

    > .benefits-content {
      margin-left: 0;
      margin-right: 0;
      z-index: 9;
    }

    p {
      max-width: 100%;
    }
  }

  .section-title {
    margin: auto;
    margin-bottom: 50px;
    z-index: 9;
    &:after {
      background-color: $green;
    }
  }

  .card {
    background: transparent;
    border: 0;

    .card-body {
      @include breakpoint(medium) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .card-text {
      text-align: center;

      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }

  .section-footer {
    margin-bottom: 90px;
    display: none;

    @include breakpoint(medium) {
      display: flex;
    }
    img {
      z-index: 9;
      max-width: 80%;
      margin: auto;
    }
  }
}
