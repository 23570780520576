.hero-section {
  position: relative;
  overflow-x: hidden;

  &:before {
    content: "";
    background: #2233b4;
    height: 600px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(xlarge) {
      height: 300px;
    }
  }

  .hero-inner {
    text-align: center;

    @include breakpoint(xlarge) {
      text-align: left;
    }
  }

  h1 {
    width: 100%;
    margin-bottom: 25px;

    @include breakpoint(xlarge) {
      width: 700px;
    }
  }

  h4 {
    font-weight: 300;
    margin-bottom: 48px;
  }

  // Background mask
  .hero-mask,
  .hero-rectangle {
    position: absolute;
    right: 0;
    bottom: 200px;

    @include breakpoint(medium) {
      bottom: 300px;
    }
    @include breakpoint(xlarge) {
      bottom: 0;
      max-width: 100%;
    }
  }

  .hero-mask {
    opacity: 0.45;
  }

  .hero-right {
    position: absolute;
    right: 0;
    width: 110%;
    bottom: 240px;

    @include breakpoint(medium) {
      width: 100%;
      bottom: 300px;
    }
    @include breakpoint(xlarge) {
      width: auto;
      right: calc(50% - 50vw - 200px);
      bottom: 0;
    }
  }

  .hero-inner {
    color: $white;
  }

  // Right hero image
  .hero-img img {
    max-width: 100%;
  }

  .btn.round-conor {
    min-width: 268px;
  }
}
