.image-text-section {
  overflow: hidden;

  // text-style
  .section-title {
    text-align: left;
    margin-bottom: 50px;

    &:after {
      margin-left: auto;

      @include breakpoint(large) {
        margin-left: 0;
      }
    }
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 3;
    letter-spacing: 2px;
    color: $grey-x-light;
  }

  p {
    font-size: 18px;
    font-weight: 300;
  }

  .text-wrap,
  .section-title {
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }
  }

  .text-wrap > a {
    display: none; // Temp hide before creating content
    color: $blue-dark;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.5px;
  }

  // image style
  .image-col {
    position: relative;
    margin-bottom: 90px;

    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

  .image-wrap {
    height: 100%;
    width: 43vw;
    width: 100%;
    max-height: auto;

    @include breakpoint(large) {
      width: 720px;
      max-height: 600px;
      position: absolute;
      top: 0;
    }
  }

  &.image-left {
    padding-bottom: 90px;
    @include breakpoint(large) {
      padding-bottom: 50px;
    }

    .image-wrap {
      right: 0;
    }
    .image-wrap.inview {
      opacity: 1;
      transition: transform 500ms, opacity 500ms, -webkit-transform 500ms;
    }
  }

  &.image-right {
    .image-wrap {
      left: 0;
    }
    .image-wrap.inview {
      opacity: 1;
      transition: transform 500ms, opacity 500ms, -webkit-transform 500ms;
    }
  }
}
