.client-showcase-section {
  @include breakpoint(large){
    padding-bottom: 100px;
  }

  h4 {
    margin-bottom: 14px;
  }
  p {
    font-weight: 300;
    line-height: 1.17;
    letter-spacing: 0.6px;
  }
  .section-title:after {
    background-color: $blue-light;
  }
  .col-lg-4 {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    .content-image,
    .text-container {
      text-align: center;
    }

    .content-image img {
      max-height: 85px;
    }

    @include breakpoint(medium) {
      flex-direction: row;

      .content-image,
      .text-container {
        text-align: left;
        width: 50%;
      }
      .content-image {
        padding-left: 30px;
      }
    }

    @include breakpoint(large) {
      flex-direction: column;
      margin-bottom: 0;

      .content-image,
      .text-container {
        width: 100%;
      }
      .content-image {
        padding-left: 0;
      }
    }
  }
}
