.contact {
  padding: 50px 0;
  background-color: $blue;

  @include breakpoint(large) {
    padding: 107px;
  }

  .section-title {
    color: $white;
    margin: auto;
    margin-bottom: 50px;
    max-width: 900px;

    &:after {
      display: none;
    }
  }
}
