//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ...
// 1. Global
// ---------

/*
 * Typo
 */
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir.ttc") format("ttc");
}

/*!
 * Primary color settings
 */

$blue: #2129ac;
$blue-light: #214bce;
$blue-dark: #1e1567;
$green: #1ae5be;
$black: #000;
$white: #fff;
$grey-dark: #282828;
$grey-light: #49484e;
$grey-x-light: #999;
$grey-body: #f7f7f7;
$grey-footer: #787878;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  xlarge: 1140px,
  xxlarge: 1440px
);

@mixin breakpoint($bp) {
  @if not map_has_key($breakpoints, $bp) {
    @error "Can't find $breakpoint: #{$bp} in $breakpoints map.";
  }

  @media (min-width: map_get($breakpoints, $bp)) {
    @content;
  }
}
